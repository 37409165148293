import _objectSpread from "/Users/adway/Documents/agora.io/ad-manage-web/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
import { mapGetters } from 'vuex';
export default {
  name: 'CustomPic',
  computed: _objectSpread({}, mapGetters('user', ['userInfo']))
};