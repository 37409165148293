var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    { staticClass: "headerAvatar" },
    [
      [
        _c("el-avatar", {
          attrs: { size: 30, src: require("@/assets/noBody.png") },
        }),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }