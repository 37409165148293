import service from '@/utils/request'; // @Tags AdConfig
// @Summary 获取项目列表
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Router /adConfig/projects [get]

export var getProjects = function getProjects(params) {
  return service({
    url: '/adConfig/projects',
    method: 'get',
    params: params
  });
}; // @Tags AdConfig
// @Summary 获取单个项目
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Router /adConfig/project [get]

export var getProject = function getProject(params) {
  return service({
    url: '/adConfig/project',
    method: 'get',
    params: params
  });
}; // @Tags AdConfig
// @Summary 获取项目的应用配置元信息环境列表
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Router /adConfig/appConfig[get]

export var getAppEnvs = function getAppEnvs(params) {
  return service({
    url: '/adConfig/appEnvs',
    method: 'get',
    params: params
  });
}; // @Tags AdConfig
// @Summary 获取项目的应用配置元信息
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Router /adConfig/appConfig[get]

export var getAppConfig = function getAppConfig(params) {
  return service({
    url: '/adConfig/appConfig',
    method: 'get',
    params: params
  });
}; // @Tags AdConfig
// @Summary 设置AppConfig元信息
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Router /adConfig/appConfig[put]

export var putAppConfig = function putAppConfig(data) {
  return service({
    url: '/adConfig/appConfig',
    method: 'put',
    data: data
  });
}; // @Tags AdConfig
// @Summary 获取项目系统配置
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Router /adConfig/configInfo[get]

export var getConfigInfo = function getConfigInfo(params) {
  return service({
    url: '/adConfig/configInfo',
    method: 'get',
    params: params
  });
}; // @Tags AdConfig
// @Summary 发布项目系统配置
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Router /adConfig/configInfo[put]

export var putConfigInfo = function putConfigInfo(data) {
  return service({
    url: '/adConfig/configInfo',
    method: 'put',
    data: data
  });
}; // @Tags AdConfig
// @Summary 发布回滚
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Router /adConfig/postRollback[post]

export var postRollback = function postRollback(data) {
  return service({
    url: '/adConfig/postRollback',
    method: 'post',
    data: data
  });
}; // @Tags AdConfig
// @Summary 取消灰度发布项目系统配置
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Router /adConfig/configInfoBeta[delete]

export var delConfigInfoBeta = function delConfigInfoBeta(data) {
  return service({
    url: '/adConfig/configInfoBeta',
    method: 'delete',
    data: data
  });
}; // @Tags AdConfig
// @Summary 获取项目的发布历史列表
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Router /adConfig/historyConfigInfos[get]

export var getHistoryConfigInfos = function getHistoryConfigInfos(params) {
  return service({
    url: '/adConfig/historyConfigInfos',
    method: 'get',
    params: params
  });
}; // @Tags AdConfig
// @Summary 获取项目的单个发布历史
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Router /adConfig/historyConfigInfo[get]

export var getHistoryConfigInfo = function getHistoryConfigInfo(params) {
  return service({
    url: '/adConfig/historyConfigInfo',
    method: 'get',
    params: params
  });
};